<template>
  <div>
    <button @click="handleToevoegen">
      + {{ recordName || (whitelist ? 'Whitelister' : 'Blacklister') }} toevoegen
    </button>
    <div :key="`${blacklist}-${whitelist}`" class="py-4">
      <UITableResponsive :items="items" :headers="[].concat(['Record', 'Opmerking'], headers)" :suffix-headers="['Acties']">
        <template #colgroup>
          <colgroup>
            <col class="w-0">
            <col class="w-auto">
          </colgroup>
        </template>
        <template #item-Record="{ item }">
          {{ `${item.type === 'EMAIL_DOMAIN' ? '*@' : ''}${item.value}` }}
        </template>
        <template #item-Opmerking="{ item }">
          <ButtonAlertOpmerking
            edit
            preview-length="30"
            :opmerking="item.opmerking"
            @edit="handleBewerken(item)"
          />
        </template>

        <template v-for="header in headers" :key="header">
          <slot :name="`item-${header}`" :item="item"></slot>
        </template>

        <template #item-Acties="{ item }">
          <button class="ml-1 btn warn" @click="handleBewerken(item)">
            Wijzigen
          </button>
          <button class="ml-1 btn error" @click="handleVerwijderen(item)">
            Verwijderen
          </button>
        </template>
      </UITableResponsive>
      <UITablePagination
        v-model:limit="limit"
        v-model:offset="offset"
        class="mt-2"
        :count="count"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import clone from 'just-clone'

import UITableResponsive from '@/components/UI/Table/Responsive.vue'

import useApi from '@/hooks/useApi'
import useModal from '@/hooks/useModal'
import usePaginatedApi from '@/hooks/usePaginatedApi'

const props = defineProps({
  blacklist: String,
  whitelist: String,
  recordName: String,
  editable: Boolean,
  headers: {
    type: Array,
    default() {
      return []
    },
  },
})
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))
const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))

const api = useApi()
const [openFixedModal] = useModal({ fixed: true })

const apiData = computed(() => ({
  blacklist: String(props.blacklist || '').toUpperCase() || null,
  whitelist: String(props.whitelist || '').toUpperCase() || null,
}))

const { limit, offset, count, items, getData } = usePaginatedApi('WHITELIST_BLACKLIST_LIST', apiData)

const handleToevoegen = () => {
  openFixedModal('FORM_WHITELIST_BLACKLIST', { callback: getData, apiData: apiData.value })
}

const handleBewerken = record => {
  openFixedModal('FORM_WHITELIST_BLACKLIST', { callback: getData, apiData: apiData.value, modelValue: clone(record) })
}

const handleVerwijderen = record => {
  const message = `${record.value} verwijderen?`
  const callback = () => api('WHITELIST_BLACKLIST_DELETE', { ...record, ...apiData.value }).then(() => getData())
  openFixedModal('BEVESTIG_ACTIE', { message, callback })
}
</script>
